import React, { Component } from "react";
import "./GastroCorona.sass";
import { Button, Boolean, Popover, Form } from "@onedash/tools";
import { Popover as PopoverV2 } from "onedash-dialog";
import { withTranslation, WithTranslation, Trans } from "react-i18next";
import RoomHeader from "./RoomHeader";
import Backend from "../../Utils/Backend/Backend";
import RoomSelection from "./RoomSelection";
import TableSelection from "./TableSelection";
import DataEntry from "./CoronaDataEntry";
import SchutzHygieneregeln from "../../Components/SchutzHygieneregeln";
import AnimationComponent from "../../Components/AnimationComponent";
import * as LoadingAnimation from "../../Resources/Animations/loading.json";
import * as ErrorAnimation from "../../Resources/Animations/error.json";
import * as SuccessAnimation from "../../Resources/Animations/success.json";
import BrowserUtils from "../../Utils/BrowserUtils";
import { GastroRoom, GuestRoom, Table } from "./GastroCoronaTypes";
import PrivacyText from "../../Components/PrivacyText";
import MenuCard from "../MenuCard/MenuCard";

const MAX_PEOPLE = 20;

class VGCorona extends Component<WithTranslation> {
	submitPopover = React.createRef<Popover>();

	dataEntry = React.createRef<any>();

	returnIntervall: undefined | number = undefined;

	tableSelection = React.createRef<TableSelection>();

	state = {
		showMenuCard: false,
		gastroRooms: [] as GastroRoom[],
		guestRooms: undefined as GuestRoom[] | undefined,
		selectedGastroRoom: undefined as GastroRoom | undefined,
		selectedTable: undefined as Table | undefined,
		globalValid: false,
		valid: {
			dataEntry: {
				state: false,
				errMessage: "wrongDataEntry",
			},
			roomSelection: {
				state: false,
				errMessage: "selectValidRoom",
			},
			tableSelection: {
				state: false,
				errMessage: "missingTable",
			},
			submitToggles: {
				state: false,
				errMessage: "unToggled",
			},
		},
		errMessage: "missingTable",
		response: undefined as undefined | { type: "S" | "E"; message: string; walletUrl?: string },

		returnTimeout: 7,
	};

	componentDidMount = () => {
		this.loadGastroRooms();
	};

	loadGastroRooms = async () => {
		const gastroRooms = await Backend.get<GastroRoom[]>(`/appSettings/gastroRooms`);
		Backend.get<GuestRoom[]>(`/appSettings/guestRooms`).then((guestRooms) => {
			this.setState({ guestRooms });
		});

		this.setState({ gastroRooms });

		// Preselect values for the case of query params
		const roomID = BrowserUtils.getParam("roomID");
		const table = BrowserUtils.getParam("table");
		if (roomID) {
			const room = gastroRooms.find((x) => x.id === roomID);
			if (room) {
				this.selectRoom(room);

				if (table) {
					const selectedTable = room.tables.find((x) => x.value === Number(table));
					if (selectedTable) this.selectTable(selectedTable);
				}
			} else {
				this.selectRoom(gastroRooms[0]);
			}
		} else {
			this.selectRoom(gastroRooms[0]);
		}
	};

	selectRoom = (selectedGastroRoom: GastroRoom) => {
		if (selectedGastroRoom !== this.state.selectedGastroRoom) {
			if (selectedGastroRoom?.allowGuestRoom !== this.state.selectedGastroRoom?.allowGuestRoom) {
				this.validate("roomSelection", false);
				this.validate("dataEntry", false);
				setTimeout(() => {
					this.dataEntry.current?.validate();
				}, 100);
			}
			this.setState({ selectedGastroRoom, selectedTable: undefined }, () => this.validate("tableSelection", false));
			this.dataEntry.current.resetEntry();
		}
	};

	selectTable = (selectedTable: Table) => {
		this.setState({
			selectedTable,
		});
		this.validate("tableSelection", true);
	};

	submitData = () => {
		const { selectedGastroRoom, selectedTable } = this.state;
		if (!selectedGastroRoom || !selectedTable) return;
		// Get Data entry data
		if (!this.dataEntry.current) return;
		if (!this.submitPopover.current) return;
		this.submitPopover.current.show();

		const data: any = {
			table: selectedTable.value,
		};
		const dataEntryData = this.dataEntry.current.getDataEntryData();
		if (typeof dataEntryData === "number") {
			// Room number
			data.guestRoomNumber = dataEntryData;
		} else {
			data.households = dataEntryData;
		}

		Backend.post<any>(`/gastro/corona/${selectedGastroRoom.id}`, data)
			.then(() => {
				this.setState({
					response: {
						type: "S",
					},
				});
				setTimeout(() => {
					this.setState({ showMenuCard: true });
					this.submitPopover.current?.close(true);
				}, 3000);

				if (BrowserUtils.getParam("external") === "true") {
					setTimeout(() => {
						window.close();
					}, 2500);
				}
			})
			.catch((err) => {
				this.setState({
					response: {
						type: "E",
						message: err,
					},
				});
				this.returnIntervall = setInterval(() => {
					let { returnTimeout } = this.state;
					returnTimeout--;
					if (returnTimeout === 0) {
						window.location.reload();
						clearInterval(this.returnIntervall);
						return;
					}
					this.setState({
						returnTimeout,
					});
				}, 1000) as any;
			});
	};

	validate = (component: "dataEntry" | "roomSelection" | "tableSelection" | "submitToggles", v: boolean) => {
		const { valid } = this.state;
		valid[component].state = v;
		let globalValid = true;
		Object.keys(valid).forEach((key) => {
			if (globalValid && !(valid as any)[key].state) {
				this.setState({ errMessage: (valid as any)[key].errMessage });
				globalValid = false;
			}
		});

		this.setState({ valid, globalValid });
	};

	render() {
		const { t } = this.props;
		const { selectedGastroRoom, selectedTable, gastroRooms, globalValid, showMenuCard } = this.state;
		const { returnTimeout } = this.state;
		const offset = gastroRooms.length > 1 ? 0 : -1;
		return (
			<div className="vg-corona">
				<RoomHeader selectedRoom={selectedGastroRoom} />
				<div className="info">
					<i className="im im-info" />
					<div className="text">{t("coronaReservation.entryText1")}</div>
				</div>
				<div className="warning">
					<i className="im im-warning" />
					<div className="text">
						<Trans i18nKey="coronaReservation.entryText2">
							Ihre Daten werden einen Monat aufbewahrt und auf Anordnung des Gesundheitsamtes diesem zur Verfügung. Die Daten
							werden <span className="bold">nicht</span> anderweitig verwendet.
						</Trans>
					</div>
				</div>

				{selectedGastroRoom && (
					<>
						{offset !== -1 && (
							<RoomSelection
								index={1 + offset}
								rooms={gastroRooms}
								selectedRoom={selectedGastroRoom}
								onRoomChange={this.selectRoom}
								t={t}
							/>
						)}
						{selectedGastroRoom.tables.length > 0 && (
							<>
								<TableSelection
									selectedRoom={selectedGastroRoom}
									onTableSelection={this.selectTable}
									index={2 + offset}
									selectedTable={selectedTable}
									ref={this.tableSelection}
									t={t}
								/>

								<DataEntry
									t={t}
									allowRoom={selectedGastroRoom.allowGuestRoom}
									guestRooms={this.state.guestRooms}
									onChange={(valid, component) => this.validate(component, valid)}
									ref={this.dataEntry}
									maxPeople={MAX_PEOPLE}
									index={3 + offset}
								/>
								<div className="submit-area">
									<h1>
										{4 + offset}. {t("coronaReservation.submitPrivacyTitle")}
									</h1>
									<Form onChange={(_v, _f, valid) => this.validate("submitToggles", valid)}>
										<Boolean name="privacy-read" required className="onedash-switch-container">
											<PrivacyText />
										</Boolean>
										<Boolean name="schutz-hygiene-read" required className="onedash-switch-container">
											<SchutzHygieneregeln />
										</Boolean>
									</Form>

									<div className={globalValid ? "error" : "error visible"}>
										<i className="im im-warning" />
										<div className="text">{t(`coronaReservation.errors.${this.state.errMessage}`)}</div>
									</div>

									<Button disabled={!globalValid} onClick={this.submitData} className="submit-btn">
										{t("coronaReservation.submitData")}
									</Button>
								</div>
							</>
						)}
					</>
				)}

				<Popover className="submit-popup" ref={this.submitPopover} closeable={false}>
					{!this.state.response && (
						<>
							<AnimationComponent width={200} height={200} animationData={LoadingAnimation} />
							<h1>{t("submit.pleaseWait")} ...</h1>
						</>
					)}
					{this.state.response && this.state.response.type === "S" && (
						<>
							<AnimationComponent options={{ loop: false }} width={200} height={200} animationData={SuccessAnimation} />
							<h1>{t("coronaReservation.success")}</h1>
						</>
					)}
					{this.state.response && this.state.response.type === "E" && (
						<>
							<AnimationComponent options={{ loop: false }} width={200} height={200} animationData={ErrorAnimation} />
							<h1>{this.state.response.message}</h1>
							<p>{t("submit.redirect", { seconds: returnTimeout })}</p>
						</>
					)}
				</Popover>
				<PopoverV2 isOpen={showMenuCard} onClose={() => this.setState({ showMenuCard: false })}>
					<MenuCard />
				</PopoverV2>
			</div>
		);
	}
}

export default withTranslation()(VGCorona);
