import React, { Component } from "react";
import { Dialog } from "@onedash/tools";
import { withTranslation, Trans, WithTranslation } from "react-i18next";

class SchutzHygieneregeln extends Component<WithTranslation> {
	schutzDialog = React.createRef<Dialog>();

	openImpress = (e: any) => {
		e.preventDefault();
		if (this.schutzDialog.current) this.schutzDialog.current.show();
	};

	render() {
		const { t } = this.props;
		return (
			<div className="privacy-text">
				<Trans i18nKey="legalText.hygiene">
					Hiermit bestätigen Sie, dass Sie unsere{" "}
					<a href="/privacy" onClick={this.openImpress}>
						Schutz- und Hygieneregeln
					</a>{" "}
					gelesen und verstanden haben.
				</Trans>
				<Dialog title="Schutz- und Hygieneregeln" buttons={[]} ref={this.schutzDialog}>
					<p>{t("dialogs.hygiene.introduction.1")}</p>
					<p>{t("dialogs.hygiene.introduction.2")}</p>

					<h1>{t("dialogs.hygiene.general.title")}</h1>
					<p>{t("dialogs.hygiene.general.1")}</p>
					<ul>
						<li>{t("dialogs.hygiene.general.2")}</li>
						<li>{t("dialogs.hygiene.general.3")}</li>
						<li>{t("dialogs.hygiene.general.4")}</li>
						<li>{t("dialogs.hygiene.general.5")}</li>
						<li>{t("dialogs.hygiene.general.6")}</li>
						<li>{t("dialogs.hygiene.general.7")}</li>
					</ul>

					<h1>{t("dialogs.hygiene.moreInfo.title")}</h1>
					<p>{t("dialogs.hygiene.moreInfo.1")}</p>
					<p>{t("dialogs.hygiene.moreInfo.2")}</p>
					<p>{t("dialogs.hygiene.moreInfo.3")}</p>
					<p>{t("dialogs.hygiene.moreInfo.4")}</p>
					<ul>
						<li>{t("dialogs.hygiene.moreInfo.5")}</li>
						<li>{t("dialogs.hygiene.moreInfo.6")}</li>
					</ul>

					<p>{t("dialogs.hygiene.moreInfo.7")}</p>
				</Dialog>
			</div>
		);
	}
}

export default withTranslation()(SchutzHygieneregeln);
