/* eslint-disable no-underscore-dangle */
const ENV = {
	default: {
		apiURL: String(window._env.REACT_APP_API_URL),
		baseURL: String(window._env.REACT_APP_BASE_URL),
	},
	serverStatus: {
		url: String(window._env.REACT_APP_STATUS_URL),
		id: String(window._env.REACT_APP_STATUS_ID),
	},
};

export default ENV;
