import React, { Component } from "react";
import { Popover, Select } from "@onedash/tools";
import { TFunction } from "i18next";
import { GastroRoom, Table } from "./GastroCoronaTypes";

interface TableSelectionProps {
	index: number;
	selectedRoom: GastroRoom;
	selectedTable?: Table;
	onTableSelection: (table: Table) => void;
	t: TFunction;
}

class TableSelection extends Component<TableSelectionProps> {
	moreInformation = React.createRef<Popover>();

	onTimeslotSelection = (e: { name: string; value: number }) => {
		const { tables } = this.props.selectedRoom;
		const selectedTable = tables.find((x) => x.value === e.value);
		if (!selectedTable) return;
		this.props.onTableSelection(selectedTable);
	};

	render() {
		const { index, selectedRoom, selectedTable, t } = this.props;
		const { tables } = selectedRoom;
		return (
			<div className="slot-selection">
				<h1>
					{index}. {t("coronaReservation.chooseATable")}
				</h1>
				<Select
					native
					required
					options={tables}
					placeholder={`${t("coronaReservation.chooseATable")} ...`}
					value={selectedTable?.value ?? '"invalid-input"'}
					name="table"
					onChange={this.onTimeslotSelection}
				/>
			</div>
		);
	}
}

export default TableSelection;
