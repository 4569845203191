import { StyleLoaderStyle } from "@onedash/tools/dist/ToolTypes";

const StyleTwenty: StyleLoaderStyle = {
	light: {
		background: "#FBFBFD",
		text: "black",

		darkdarkGray: "#3a2850",
		darkGray: "#8B8B8B",
		middleGray: "#D4D4D4",
		lightGray: "#F1F2F5",

		white: "white",

		primary: "#FF7A59",
		secondary: "#0694B0",
		warning: "#b59022",
		warningLight: "rgba(255,193,7, 0.2)",
		secondaryLight: "rgba(6, 148, 176, 0.2)",
		primaryLight: "rgba(255,122,89, 0.2)",
	},
	dark: {},
	all: {
		boxShadow: "0px 3px 15px rgba(0,0,0,0.25)",
		lightBoxShadow: "0px 3px 7.5px rgba(0,0,0,0.15)",
		"tools-btn-primary": "#FF7A59",
	},
};

export default StyleTwenty;
