/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { TFunction } from "i18next";
import { GastroRoom } from "./GastroCoronaTypes";

interface RoomSelectionProps {
	index: number;
	rooms: GastroRoom[];
	selectedRoom: GastroRoom;
	onRoomChange: (pool: GastroRoom) => void;
	t: TFunction;
}

class RoomSelection extends Component<RoomSelectionProps> {
	changeRoom = (e: any, pool: GastroRoom) => {
		if (e.target.className.includes("im-info")) return;
		this.props.onRoomChange(pool);
	};

	render() {
		const { index, rooms, t } = this.props;

		return (
			<div className="pool-selection">
				<h1>
					{index}. {t("coronaReservation.chooseRestaurant")}
				</h1>
				<div className="slider" aria-label={t("coronaReservation.chooseRestaurant")}>
					{rooms.map((room, i) => (
						<div
							aria-label={room.name}
							key={i as any}
							className={this.props.selectedRoom === room ? "selected pool" : "pool"}
							onClick={(e) => this.changeRoom(e, room)}>
							<div className="img" style={{ backgroundImage: `url(${room.img})` }} />
							<p className="name">{room.name}</p>
						</div>
					))}
				</div>
			</div>
		);
	}
}

export default RoomSelection;
