import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import DeTranslation from "./locales/de-DE.json";
import EnTranslation from "./locales/en.json";

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		load: "languageOnly",
		supportedLngs: ["de", "en"],
		fallbackLng: "en",

		resources: {
			de: {
				translation: DeTranslation,
			},
			en: {
				translation: EnTranslation,
			},
		},

		debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development",

		keySeparator: ".", // we use content as keys
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
