const VG_LIST: Verbandsgemeinde[] = [
	{
		name: "Rotisserie - Royale",
		appID: "0000004562",
		subdomain: "rotisserie-royale",
		default: true,
	},
	{
		name: "Richtershof - Bistrobar Remise",
		appID: "0000008956",
		subdomain: "richtershof-remise",
	},
	{
		name: "Richtershof - Culinarium R 2.0",
		appID: "0000008957",
		subdomain: "richtershof-culinarium",
	},
];

export default VG_LIST;
