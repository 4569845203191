/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from "react";
import { Dialog } from "@onedash/tools";
import { ValueLabelPair } from "@onedash/tools/dist/ToolTypes";
import { Popover } from "onedash-dialog";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { isIOS } from "react-device-detect";
import { withTranslation, WithTranslation } from "react-i18next";
import PACKAGE from "../../../package.json";
import VG_LIST from "../../Resources/GASTRO_LIST";
import Contract from "../../Resources/SVG/contract.svg";
import PrivacyIcon from "../../Resources/SVG/privacy.svg";
import MenuIcon from "../../Resources/SVG/menu.svg";
import DeIcon from "../../Resources/SVG/de.svg";
import GbIcon from "../../Resources/SVG/gb.svg";
import "./Home.scss";
import Privacy from "../../Components/Privacy";
import Impress from "../../Components/Impress";
import Backend from "../../Utils/Backend/Backend";
import MenuCard from "../MenuCard/MenuCard";

class Home extends Component<WithTranslation> {
	privacyDialog = React.createRef<Dialog>();

	impressDialog = React.createRef<Dialog>();

	state = {
		open: false,
		menuCardLink: undefined as undefined | string,
		showMenuCard: false,
	};

	componentDidMount() {
		this.loadMenuCardLink();
	}

	loadMenuCardLink = async () => {
		const [menuCardLink] = await Promise.all([Backend.get<string | undefined>(`/appSettings/menuCardLink`)]);
		this.setState({ menuCardLink });
	};

	changeGastro = (obj: { name: string; value: Verbandsgemeinde }) => {
		const vg = obj.value;
		const location = window.location.href;
		const dot = location.indexOf(".");

		const start = location.indexOf("://") + 3;
		const newURL = location.substr(0, start) + vg.subdomain + location.substr(dot, location.length);
		window.location.href = newURL;
	};

	toggleHome = () => {
		const body = document.querySelector("body");
		if (!body) return;
		// IOS disables scrolling in impress and privacy dialog => So dont disable it at all
		if (isIOS === false) {
			if (this.state.open === false) {
				disableBodyScroll(body);
			} else {
				enableBodyScroll(body);
			}
		}
		this.setState({
			open: !this.state.open,
		});
	};

	render() {
		const { t, i18n } = this.props;
		const { open, menuCardLink, showMenuCard } = this.state;
		const selectOptions: ValueLabelPair[] = [];
		VG_LIST.forEach((vg) => {
			selectOptions.push({
				label: vg.name,
				value: vg,
			});
		});

		const isDE = () => i18n.language.indexOf("de") !== -1;
		return (
			<>
				<button onClick={this.toggleHome} className={open === true ? "home-btn open" : "home-btn"}>
					<i className="im im-angle-down" />
				</button>
				<div aria-hidden={!open} className={open === true ? "home open" : "home"}>
					<div className="wrapper" style={{ minHeight: window.innerHeight - 130 }}>
						<div>
							<div className="app-list">
								<AppTile
									img={Contract}
									onClick={() => this.impressDialog.current?.show()}
									title={t("dialogs.impress.title")}
									description={t("dialogs.impress.description")}
								/>
								<AppTile
									img={PrivacyIcon}
									onClick={() => this.privacyDialog.current?.show()}
									title={t("dialogs.privacy.title")}
									description={t("dialogs.privacy.description")}
								/>
								{menuCardLink && (
									<AppTile
										img={MenuIcon}
										onClick={() => this.setState({ showMenuCard: true })}
										title={t("app.menuCard.title")}
										description={t("app.menuCard.description")}
									/>
								)}
								<AppTile
									img={isDE() ? GbIcon : DeIcon}
									onClick={() => i18n.changeLanguage(isDE() ? "en" : "de")}
									title={isDE() ? "English" : "Deutsch"}
									description={isDE() ? "View this page on english" : "Ansicht dieser Seite auf Deutsch"}
								/>
							</div>
						</div>
						<div className="footer">
							<h3>Version {PACKAGE.version}</h3>
							<a href="https://onedash.de" className="onedash-logo">
								<span>One</span>
								<span>Dash</span>
								<span>.</span>
							</a>
						</div>
					</div>
				</div>

				<Dialog title={t("dialogs.privacy.title")} buttons={[]} ref={this.privacyDialog}>
					<Privacy inline />
				</Dialog>
				<Dialog title={t("dialogs.impress.title")} buttons={[]} ref={this.impressDialog}>
					<Impress inline />
				</Dialog>
				<Popover isOpen={showMenuCard} onClose={() => this.setState({ showMenuCard: false })}>
					<MenuCard />
				</Popover>
			</>
		);
	}
}

interface AppTileProps {
	title: string;
	description: string;
	img: string;
	onClick?: () => void;
}
const AppTile = ({ title, description, img, onClick }: AppTileProps) => {
	return (
		<button onClick={onClick} className="app-tile">
			<div>
				<img src={img} alt={title} />
				<h2>{title}</h2>
				<p className="description">{description}</p>
			</div>
		</button>
	);
};

export default withTranslation()(Home);
