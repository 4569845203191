import React from "react";
import { useTranslation } from "react-i18next";
import Backend from "../../Utils/Backend/Backend";
import "./MenuCard.sass";

const initialState = {
	menuCardLink: undefined as undefined | string,
};

const MenuCard = () => {
	const [state, update] = React.useState(initialState);
	const { t, i18n } = useTranslation();

	React.useEffect(() => {
		const loadAppSettings = async () => {
			const [menuCardLink] = await Promise.all([Backend.get<string | undefined>(`/appSettings/menuCardLink`)]);
			update({
				menuCardLink,
			});
		};
		loadAppSettings();
	}, []);

	return (
		<div className="menu-card-wrapper">
			<div className="menu-card">
				<iframe
					width="720"
					height="480"
					frameBorder={0}
					src={`${state.menuCardLink}?lng=${i18n.language}`}
					title={t("menu-card.iframe-title")}
				/>
			</div>
		</div>
	);
};

export default MenuCard;
