/* eslint-disable default-case */
export default function reducer(state = {} as any, action: any) {
	switch (action.type) {
		case "DARK_LIGHT_CHANGE":
			state.themeMode = action.mode;
			break;
		case "VERBANDSGEMEINDE":
			state.vg = action.vg;
			break;
	}
	return state;
}
