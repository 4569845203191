import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import BrowserHistory from "./Utils/BrowserHistory";
import App from "./Pages/App/App";
import * as serviceWorker from "./serviceWorker";
import "./Styles/default.scss";
import "./Styles/onedash-dialog.sass";
import "./Styles/onedash-form.sass";
import "./i18n/i18n";

ReactDOM.render(
	<Router history={BrowserHistory as any}>
		<App />
	</Router>,
	document.getElementById("root")
);
serviceWorker.unregister();
