/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Dialog } from "@onedash/tools";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Privacy from "./Privacy";

interface PrivacyTextProps {
	onClick?: () => void;
}

const PrivacyText = (props: PrivacyTextProps) => {
	const privacyDialog = React.useRef<Dialog>();
	const { t } = useTranslation();
	const openPrivacy = (e: any) => {
		e.preventDefault();
		if (privacyDialog.current) privacyDialog.current.show();
	};

	const onClick = (e: any) => {
		if (props.onClick && e.target.classList.contains("privacy-text")) props.onClick();
	};

	return (
		<div className="privacy-text" onClick={onClick}>
			<Trans t={t} i18nKey="legalText.privacy">
				Hiermit bestätigen Sie, dass Sie unsere{" "}
				<a href="/privacy" onClick={openPrivacy}>
					Datenschutzerklärung
				</a>{" "}
				gelesen und verstanden haben.
			</Trans>
			<Dialog title="Datenschutz&shy;erklärung" buttons={[]} ref={privacyDialog as any}>
				<Privacy inline />
			</Dialog>
		</div>
	);
};

export default PrivacyText;
