import React from "react";
import { GastroRoom } from "./GastroCoronaTypes";

interface RoomHeaderProps {
	selectedRoom?: GastroRoom;
}

const RoomHeader = ({ selectedRoom }: RoomHeaderProps) => {
	return (
		<div className="header" style={{ backgroundImage: `url(${selectedRoom?.img})` }}>
			{selectedRoom && (
				<>
					<div className="content">
						<h1 className="name">{selectedRoom.name}</h1>
					</div>
					<div className="overlay" />
				</>
			)}
		</div>
	);
};

export default RoomHeader;
