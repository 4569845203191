/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import { Form, Input, Button, Boolean, Card } from "@onedash/tools";
import { TFunction } from "i18next";
import { GuestRoom } from "./GastroCoronaTypes";

export interface DataEntryProps {
	index: number;
	maxPeople: number;
	onChange: (valid: boolean, component: "dataEntry" | "roomSelection") => void;
	t: TFunction;
	allowRoom?: boolean;
	guestRooms?: GuestRoom[];
}
interface Household {
	num: number;
	ref: React.RefObject<Form>;
	valid: boolean;
}

class DataEntry extends Component<DataEntryProps> {
	roomForm = React.createRef<Form>();

	state = {
		isHotelGuest: false,
		households: [
			{
				num: 0,
				ref: React.createRef<Form>(),
				valid: false,
			},
		] as Household[],
	};

	public resetEntry = () => {
		this.setState({ isHotelGuest: false });
	};

	public getDataEntryData = (): any[] | number => {
		// "normal guest => get households"
		if (!this.state.isHotelGuest) {
			const households = this.state.households.map((household) => {
				if (household.ref.current) {
					const data = household.ref.current.getData();
					const members = Object.keys(data)
						.filter((x) => x.indexOf("member") !== -1 && data[x] !== undefined)
						.map((x) => data[x]);
					return {
						name: `${data.firstName} ${data.lastName}`,
						email: data.email,
						street: data.address,
						plz: data.plz,
						place: data.city,
						phone: data.phone,
						members,
					};
				}
				return <></>;
			});
			return households;
		}
		// hotel guest
		const formData = this.roomForm.current?.getData();
		return Number(formData["room-number"]);
	};

	addHousehold = () => {
		const { households } = this.state;
		households.push({
			num: 0,
			ref: React.createRef<Form>(),
			valid: false,
		});
		this.setState({ households }, this.validate);
	};

	deleteHousehold = (index: number) => {
		const { households } = this.state;
		households.splice(index, 1);
		this.setState({ households }, this.validate);
	};

	addMember = (index: number) => {
		const { households } = this.state;

		households[index].num++;
		this.setState({ households });
	};

	removeMember = (index: number) => {
		const { households } = this.state;

		households[index].num--;
		this.setState({ households });
	};

	validate = () => {
		let globalValid = true;
		this.state.households.forEach((hh) => {
			if (!hh.valid) globalValid = false;
		});
		this.props.onChange(globalValid, "dataEntry");
		this.props.onChange(true, "roomSelection");
	};

	onChange = (h: Household, valid: boolean) => {
		const { households } = this.state;
		h.valid = valid;
		this.setState({ households }, this.validate);
	};

	checkRoom = (enteredRoomNumber: number) => {
		let valid = false;
		const roomNum = Number(String(enteredRoomNumber).trim());

		const rooms = this.props.guestRooms;
		rooms?.forEach((room) => {
			switch (room.type) {
				case "range":
					if (room.from <= roomNum && roomNum <= room.to) {
						valid = true;
					}
					break;
				default:
					valid = false;
			}
		});
		return valid;
	};

	changedRoomNumber = (_values: any, _control: Form, valid: boolean) => {
		this.props.onChange(valid, "roomSelection");
		this.props.onChange(true, "dataEntry");
	};

	render() {
		const { t } = this.props;
		const { index, maxPeople } = this.props;
		const { households } = this.state;
		const members = households.reduce((x, y) => x + y.num, 0) + households.length;

		const houseHoldTexts = [
			this.props.t("numbers.first"),
			this.props.t("numbers.second"),
			this.props.t("numbers.third"),
			this.props.t("numbers.fourth"),
			this.props.t("numbers.fifth"),
			this.props.t("numbers.sixth"),
			this.props.t("numbers.seventh"),
			this.props.t("numbers.eighth"),
		];
		return (
			<div className="data-entry">
				<h1>
					{index}. {t("coronaReservation.personalDataTitle")}
				</h1>

				{this.props.allowRoom && this.props.guestRooms && (
					<Card>
						<Form ref={this.roomForm} onChange={this.changedRoomNumber}>
							<Boolean
								name="is-hotel-guest"
								onChange={(obj: { value: any; name: string }) => {
									this.setState({ isHotelGuest: obj.value }, () => {
										this.props.onChange(obj.value !== true, "roomSelection");
										this.props.onChange(obj.value === true, "dataEntry");
									});
								}}
								className="onedash-switch-container splitted-switch">
								<h2>{t("coronaReservation.questionHotelGuest")}</h2>
							</Boolean>
							{this.state.isHotelGuest && (
								<Input
									required
									label={t("coronaReservation.roomNumber")}
									onValidate={this.checkRoom}
									type="number"
									autoComplete="none"
									name="room-number"
								/>
							)}
						</Form>
					</Card>
				)}

				{!this.state.isHotelGuest &&
					households.map((x, i) => (
						<Form onChange={(_data, _form, valid) => this.onChange(x, valid)} ref={x.ref} className="household" key={i as any}>
							{i !== 0 && <i className="delete-household im im-minus-circle" onClick={() => this.deleteHousehold(i)} />}
							<h2>
								{houseHoldTexts[i] ?? t("dataInput.additionalHousehold")} {t("dataInput.household")}
							</h2>
							<Input required label={t("dataInput.firstName")} autoComplete="given-name" name="firstName" />
							<Input required label={t("dataInput.lastName")} autoComplete="family-name" name="lastName" />
							<Input type="email" label={t("dataInput.email")} autoComplete="email" name="email" />
							<Input required type="tel" label={t("dataInput.phone")} autoComplete="tel" name="phone" />
							<Input required label={t("dataInput.address")} autoComplete="street-address" name="address" />
							<Input required label={t("dataInput.plz")} autoComplete="postal-code" name="plz" />
							<Input required label={t("dataInput.location")} autoComplete="address-level2" name="city" />
							<Input label={t("dataInput.country")} autoComplete="country-name" name="country" />

							<div className="info">
								<i className="im im-info" />
								<div className="text">{t("coronaReservation.textAdditionalPerson")}</div>
							</div>
							<div className="members">
								{[...Array(x.num)].map((_x, ii) => (
									<div key={ii as any} className={ii === x.num - 1 ? "member-input last-item" : "member-input"}>
										<Input
											label={`${ii + 2}. ${t("coronaReservation.resident")}`}
											autoComplete="off"
											name={`member-${ii}`}
										/>
										{ii === x.num - 1 && <i className="im im-minus-circle" onClick={() => this.removeMember(i)} />}
									</div>
								))}

								<div
									onClick={() => this.addMember(i)}
									className={members >= maxPeople ? "disabled member-input new-member" : "member-input new-member"}>
									<p>
										{t("dataInput.additionalHousehold")} {t("coronaReservation.resident")}
									</p>
									<i className="im im-plus-circle" />
								</div>
							</div>
						</Form>
					))}
				{!this.state.isHotelGuest && (
					<Button disabled={members >= maxPeople} onClick={this.addHousehold}>
						{t("coronaReservation.addHousehold")}
					</Button>
				)}
			</div>
		);
	}
}

export default DataEntry;
