import React, { Component } from "react";
import { StyleLoader, Spinner } from "@onedash/tools";
import { createStore } from "redux";
import "./App.scss";
import { Switch, Route, Redirect } from "react-router-dom";
import moment from "moment";
import STYLE_TWENTY from "../../Resources/Styles/StyleTwenty";
import reducer from "../../Utils/Redux/Reducer";
import "moment/locale/de";
import VG_LIST from "../../Resources/GASTRO_LIST";
import Action from "../../Utils/Redux/Action";
import Backend from "../../Utils/Backend/Backend";
import Home from "../Home/Home";
import ENV from "../../Utils/ENV";
import ServerStatus from "../../Utils/ServerStatus";
import BrowserUtils from "../../Utils/BrowserUtils";
import MenuCard from "../MenuCard/MenuCard";
import GastroCorona from "../GastroCorona/GastroCorona";

moment.locale("de");

declare global {
	interface Window {
		reduxStore: {
			subscribe: (cb: () => void) => void;
			getState: () => ReducerType;
			dispatch: (arg: any) => void;
		};
		_env: any;
	}
}
interface AppProps {}

class App extends Component<AppProps> {
	state = {
		theme: "light" as "light" | "dark",
		isLoaded: false,
	};

	constructor(props: AppProps) {
		super(props);
		// eslint-disable-next-line no-new
		new ServerStatus(ENV.serverStatus.url, ENV.serverStatus.id);
		window.reduxStore = createStore(reducer);
	}

	componentDidMount() {
		const store = window.reduxStore;
		this.selectVG();
		Backend.init();

		store.subscribe(() => {
			const data = window.reduxStore.getState();
			if (data.themeMode) {
				this.setState({ theme: data.themeMode });
			}
		});
	}

	selectVG = () => {
		const subdomain = window.location.host.split(".")[0];
		let vg = VG_LIST.find((x) => x.subdomain === subdomain);
		if (vg) {
			Action.selectVG(vg);
			this.setState({
				isLoaded: true,
			});
			return;
		}

		// Check path param (Used for redirect)
		const appID = BrowserUtils.getParam("appID");
		if (appID) {
			vg = VG_LIST.find((x) => x.appID === appID);
			if (vg) {
				const { baseURL } = ENV.default;
				if (baseURL === undefined) return;
				const start = baseURL.indexOf("://") + 3;
				const newURL = `${baseURL.substr(0, start)}${vg.subdomain}.${baseURL.substr(start, baseURL.length)}${
					window.location.search
				}`;
				window.location.href = newURL;
				return;
			}
		}

		// Select default
		vg = VG_LIST.find((x) => x.default === true);
		if (vg) {
			// eslint-disable-next-line no-console
			console.warn("The selected subdomain does not exist. The default one will be used");
			const { baseURL } = ENV.default;
			if (baseURL === undefined) return;
			const start = baseURL.indexOf("://") + 3;
			const newURL = `${baseURL.substr(0, start)}${vg.subdomain}.${baseURL.substr(start, baseURL.length)}`;
			window.location.href = newURL;
		}
	};

	render() {
		const { isLoaded } = this.state;
		return (
			<StyleLoader toolStyle="twenty" theme={this.state.theme} additionalStyle={STYLE_TWENTY}>
				{isLoaded && (
					<>
						<Home />
						<Switch>
							<Route path="/menu-card" component={MenuCard} />
							<Route path="/corona-reservation" component={GastroCorona} />
							<Redirect to={{ pathname: "/corona-reservation", search: window.location.search }} />
						</Switch>
					</>
				)}
				{!isLoaded && <Spinner defaultVisible fullPage />}
			</StyleLoader>
		);
	}
}

export default App;
